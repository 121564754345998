import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import {FormBuilder} from '@angular/forms';
import { Message, MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { NavbarService } from 'src/app/services/navbar-service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import {  Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  /* Pie chartjs genero  */
  employees:any[] = [];
  genero: any[] = [];
  assinatura: any[];
  aviso: any[];
  recibo: any[];
  atestado: any[];
  companies: any[] = [];
  companiesSelected: any[] = [];
  mesMovimentostart: Date;
  mesMovimentoEnd: Date;
  hasCompanies: any[] = [];
  permission:any
  pending:any;
  approved:any;
  canceled:any;
  concluded:any;
  marginPositive:any;
  marginNegative:any;
 
  legendPosition: any = 'below';

  colorScheme1:any = {
    domain: ['#3B82F6']
  };

  colorScheme2:any = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  colorScheme3:any = {
    domain: ['#F472B6', '#3B82F6']
  };


  public welcomeMessage: string = '';
  navbarVisible: boolean;
  public userLogged: any;
  basicData: any;
  basicOptions: any;
  empresa: any;
  value: number = 123;
  knobForm = this.formBuilder.group({
    value: [30],
  });

  public alert: Message[] = [
    {
      severity: 'info',
      summary: 'Funcionários ativaram o aplicativo',
    },
  ];
  constructor(
    public storageService: StorageService,
    public formBuilder: FormBuilder,
    private apiService: ApiService,
    private navbarService: NavbarService,
    private dashboardService:DashboardService,
    private messageService: MessageService,
    private router: Router
  ) {
    
  }

  ngOnInit():any{
    this.userLogged = this.storageService.getUserLogado();
    this.getPermissionUsers();
    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });  
  }
/* DASHBOARD RHAPP*/
 async getPermissionUsers(){
  this.apiService
  .getPermissionUSers(this.userLogged.id).subscribe({
    next: (res:any) => {
     res.roles.map((item:any) => {
      this.permission = item.roles
      /* rhapp */
      if(this.permission.description !=='comercial'){
        this.getdataDashBoard();
        this.countEmployee();
        this.countMedicalCertificate();
        this.countWarning();
        this.countAceiptReceipt();
        this.countSignature();
        this.getCompanis();
      }
      /* comercial */
      if(this.permission.description === 'comercial') {
       this.getRequestsPending();
       this.getDashboardApproved();
       this.getDashboardCanceled();
       this.getDashboardConcluded();
       this.getDashboardMarginPositive();
       this.getDashboardMarginNegative();
      }
     }) 
    },
    complete: () => {},
    error: () => {}
  })
 }

 async countEmployee(){
    this.dashboardService.countEmploye().subscribe({
      next: (res:any) => {
          this.employees = res
          const {genero} = res[0]
         Object.assign(this,  {genero}  );
      },
      complete: () => {},
      error: () => {}
    })
  }

  async countMedicalCertificate(){
    this.dashboardService.countMedicalCertificate().subscribe({
      next: (res:any) => {
        const {atestado} = res
        Object.assign(this, { atestado });
      },
      complete: () => {},
      error: () => {}
    })
  }

  async countWarning(){
    this.dashboardService.countWarning().subscribe({
      next: (res:any) => {
        const {aviso} = res
        Object.assign(this, { aviso });
      },
      complete: () => {},
      error: () => {}
    })
  }

  async countAceiptReceipt(){
    this.dashboardService.countAceiptReceipt().subscribe({
      next: (res:any) => {
        const {recibo} = res
        Object.assign(this, { recibo });
      },
      complete: () => {},
      error: () => {}
    })
  }


  async countSignature(){
    this.dashboardService.countSignature().subscribe({
      next: (res:any) => {
        const {assinatura} = res
        Object.assign(this, { assinatura });
      },
      complete: () => {},
      error: () => {}
    })
  }

  async getCompanis(){
    this.dashboardService.getCompanies().subscribe({
      next: (res:any) => {
        res.map((item:any) => {
          this.companies.push({
            name: item.nome_fantasia,
            id: item.id
          });
        })
      },
      complete: () => {},
      error: () => {}
    })
  }

  async filtersDashboards(){
    if(this.companiesSelected.length !== 0 && this.mesMovimentostart !== undefined && this.mesMovimentoEnd !== undefined){
      this.hasCompanies = [];
      this.companiesSelected.map((item) => {
        this.hasCompanies.push(item.id)
      })
      this.dashboardService.searchFiltersDashboard(this.hasCompanies, this.mesMovimentostart, this.mesMovimentoEnd).subscribe({
        next: (res:any) => {
          const {aviso, recibo} = res
          Object.assign(this, { aviso });
          Object.assign(this, { recibo });
        },
        complete: () => {},
        error: () => {}
      });
      
    }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Pesquisar',
          detail: 'Por favor informar os dados para pesquisar',
        });
    }
  }


  async refreshDashbopard(){
   await this.countWarning();
   await this.countAceiptReceipt();
  }



  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }
  
  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }
  
  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  calcTime(): string {
    const date = new Date();
    const hour = date.getHours();

    if (hour <= 12) return 'Bom dia';
    else if (hour <= 18) return 'Boa tarde';
    else return 'Boa noite';
  }
  getdataDashBoard() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.apiService.getDadosDashboard().subscribe((res) => {
        this.empresa = res;
      })
      .add(() => {
        this.basicData = {
          labels: this.empresa.labels,
          datasets: [
            {
              label: 'Quantitativo de funcionários',
              data: this.empresa.total,
              backgroundColor: [
                'rgba(255, 159, 64, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 102, 255, 0.2) ',
              ],
              borderColor: [
                'rgb(255, 159, 64)',
                'rgb(75, 192, 192)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
              ],
              borderWidth: 1,
            },
          ],
        };

        this.basicOptions = {
          plugins: {
            legend: {
              labels: {
                color: textColor,
                font: {
                  size: 9,
                },
              },
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: textColorSecondary,
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false,
              },
            },
            x: {
              ticks: {
                color: textColorSecondary,
              },
              grid: {
                color: surfaceBorder,
                drawBorder: false,
              },
            },
          },
        };
      });
  }

  /* DASHBOARD COMMERCIAL */
async  getRequestsPending(){
    this.dashboardService.getRequestPending().subscribe({
      next: (res:any) => {
        this.pending = res
      },
      complete: () => {},
      error: () => {}
    })
  }

async getDashboardApproved(){
  this.dashboardService.getRequestApproved().subscribe({
    next: (res:any) => {
      this.approved = res
    },
    complete: () => {},
    error: () => {}
  })
}

async getDashboardCanceled(){
  this.dashboardService.getRequestCanceled().subscribe({
    next: (res:any) => {
      this.canceled = res
    },
    complete: () => {},
    error: () => {}
  })
}

async getDashboardConcluded(){
  this.dashboardService.getRequestConcluded().subscribe({
    next: (res:any) => {
      this.concluded = res
    },
    complete: () => {},
    error: () => {}
  })
}

async getDashboardMarginPositive(){
  this.dashboardService.getMarginPositive().subscribe({
    next: (res:any) => {
      const {marginPositive} = res
      Object.assign(this, { marginPositive });
    },
    
    complete : () => {},
    error: () => {}
  })
}

async getDashboardMarginNegative(){
  this.dashboardService.getMarginNegative().subscribe({
    next: (res:any) => {
      const {marginNegative} = res
      Object.assign(this, { marginNegative });
    },
    
    complete : () => {},
    error: () => {}
  })
}

goPage(route: string){
 console.log('oi');
 
 this.router.navigateByUrl(route)
}
}
