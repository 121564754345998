import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { DocConcluidedWesign } from 'src/app/models/docConcluidedWesign';
import { PayloadNotification } from 'src/app/models/payloadNotification';
import { ApiService } from 'src/app/services/api.service';
import { VacationService } from 'src/app/services/gpe/vacation.service';
import { NavbarService } from 'src/app/services/navbar-service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/services/utils.service';


@Component({
  selector: 'app-wesign-doc-concluded',
  templateUrl: './wesign-doc-concluded.component.html',
  styleUrls: ['./wesign-doc-concluded.component.scss']
})
export class WesignDocConcludedComponent implements OnInit {

  docConcluded: DocConcluidedWesign[] = [];
  public navbarVisible: boolean;
  doConcludedSelected: DocConcluidedWesign[] = [];
  loading: boolean = false;
  visible: boolean = false;
  docCancel: any[] = [];
  totalRecords: number = 0;
  public docForm: FormGroup;
  avisoFilter: any[] = [];
  editForm: FormGroup;
  visibleEdit: boolean = false

  constructor(
    private vacation: VacationService,
    private messageService: MessageService,
    private vacationService: VacationService,
    public formBuilder: FormBuilder,
    private utilsService: UtilsService,
    private navbarService: NavbarService,
    private storageService: StorageService,
    private api: ApiService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.editForm = this.fb.group({
      ddd_celular: ['', [Validators.required, Validators.maxLength(2), Validators.minLength(2)]],
      celular: ['', [Validators.required, Validators.maxLength(9), Validators.minLength(9)]],
      email: ['', [Validators.email]],
      nome: ['', [Validators.required]],
      fer_ini: ['', Validators.required],
      fer_fim: ['', Validators.required],
      dias_fer: ['', Validators.required]
    });

    this.docForm = this.formBuilder.group({
      motivo_cancelamento: ['', [Validators.required]],
    });

    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });

    this.avisoFilter = [
      {nome_fantasia: 'nome_fantasia'},
      {nome: 'nome'},
      {cpf:'cpf'},
      {matricula: 'matricula'},
      {fer_ini: 'fer_ini'},
      {fer_fim: 'fer_fim'},
      {mes_movimento: 'mes_movimento'},
      {created_at: 'created_at'},
      {warning_sended_at: 'warning_sended_at'},
      {num_centro_custo: 'num_centro_custo'}
    ];
  }

  listDocConcluded(event: LazyLoadEvent) {
    this.loading = true;
    this.docConcluded = [];
    this.vacation.getListDocwesignConcluded(event, this.totalRecords).subscribe({
      next: async (res: any) => {
        const promiseConcluded = res.data.map(async (item: any) => {
          this.docConcluded.push({
            ...item
          })
        })
        await Promise.all(promiseConcluded);
        this.totalRecords = res.totalRecords
      },
      complete: () => { this.loading = false },
      error: () => { }
    })
  }

  async printDocwessignCloclued(doc: DocConcluidedWesign) {
    const {nome} = doc.usuarios.dados_funcionarios;
    const {id_doc} = doc?.doc_signature[0]
    switch (doc.doc_signature[0].status) {
      case 'Concluded':
        try {
          const doc =  this.vacationService.getPdfConcludedWesing(id_doc).subscribe({
            next: async (res:any) => {
              await this.utilsService.ptintDocWesignConcluded(res, nome);
            },
            complete: () => {},
            error: () => {}
          });
        } catch (error) {
          
        }
        break;      
      case 'Canceled':
        try {
           this.vacationService.getPdfCanceledWesing(id_doc).subscribe({
            next: async (res:any) => {
             window.open(res.url, '__blank')
            },
            complete: () => {},
            error: () => {}
          });
        } catch (error) {
          
        }
      break;

      default:
        
        break;
    }
  }


  showmodal(doc: DocConcluidedWesign) {
    this.doConcludedSelected = [doc];
    this.visible = true;
    this.docCancel.push({
      empresa_fk: doc.empresa_fk,
      usuario_fk: doc.usuario_fk,
      fer_id: doc.id,
      wesign: {
        id_doc: doc?.doc_signature[0]?.id_doc
      }
    })
  }

  cancelDocWessign() {
    this.loading = true
    const motivo = '-';
    const motivo_cancelamento = this.docForm.value.motivo_cancelamento;
    let tokens = [];
    const filterTokens = this.doConcludedSelected.filter((item) => item.usuarios.notifications_tokens.length > 0);
    filterTokens.map((item: any) => {
      tokens.push(item.usuarios.notifications_tokens[0]?.token)
    });

    /* DESATIVADO PARA NÂO ENVIAR NOTIFICAÇÂO DE CANCELAMENTO */
    const sendToken: PayloadNotification = {
      tokens: [],//tokens,
      notification: {
        title: 'Olá',
        body: 'Férias canceladas, verificar com departamento pessoal'
      },
      data: {
        action: '/ferias',
        intent: 'FERIAS_INTENT'
      }
    }

    this.vacationService.definitiveCancellationDocVacantion(this.docCancel, motivo_cancelamento, motivo, sendToken).subscribe({
      next: (res: any) => {
        if (res.status === 201 && res.cancelDoc === true && res.notificationSent === true) {
          this.messageService.add({
            severity: 'info',
            summary: 'Férias cancelada',
            detail: res.message,
          });
        }
        if (res.status === 201 && res.cancelDoc === true && res.notificationSent === false) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Férias cancelada',
            detail: res.message,
          });
        }
      },
      complete: () => {
        this.docConcluded = [];
        this.docCancel = [];
        this.docForm.reset();
        this.visible = false,
          this.loading = false
        this.listDocConcluded({ first: 0, rows: 10 });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Férias cancelada',
          detail: 'Não foi possivél fazer cancelamento de ferias',
        });
      }
    })
  }

  async downloadSignatureMnualAws(doc: any) {
  
    
    try {
      switch (doc?.doc_signature_manual[0]?.mime_type) {
        case 'image/jpeg':
          this.loading = true;
          try {
            let { token } = this.storageService.getUserLogado();
            let url = `${this.api.urlApi}/aws/s3img/1/14936?img=${doc?.doc_signature_manual[0]?.url}&token=${token}`;
            const base64Img: any = await this.utilsService.convertImageToBase64(url);
            await this.utilsService.formatImgForPdf(base64Img, doc);
            this.loading = false;
          } catch (error) {
            console.log(error);
          }
          break;
        case 'image/png':
          this.loading = true;
          try {
            let { token } = this.storageService.getUserLogado();
            let url = `${this.api.urlApi}/aws/s3img/1/14936?img=${doc?.doc_signature_manual[0]?.url}&token=${token}`;
            const base64Img: any = await this.utilsService.convertImageToBase64(url);
            await this.utilsService.formatImgForPdf(base64Img, doc);
            this.loading = false;
          } catch (error) {
            console.log(error);
          }
          break;

          case 'image/heif':
          this.loading = true;
          try {
            let { token } = this.storageService.getUserLogado();
            let url = `${this.api.urlApi}/aws/s3img/1/14936?img=${doc?.doc_signature_manual[0]?.url}&token=${token}`;
            const base64Img: any = await this.utilsService.convertImageToBase64(url);
            await this.utilsService.formatImgForPdf(base64Img, doc);
            this.loading = false;
          } catch (error) {
            console.log(error);
          }
          break;

        default:
          this.loading = true;
          try {
            let { token } = this.storageService.getUserLogado();
            let url = `${this.api.urlApi}/aws/s3img/1/14936?img=${doc?.doc_signature_manual[0]?.url}&token=${token}`;
            await this.utilsService.getAtestadoAws(doc, url);
            this.loading = false;
          } catch (error) {
            console.log(error);
          }
          break;
      }
    } catch (error) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Ops',
        detail:
          'Error ao abrir pdf',
      });
    }


  }

  async exportExcel() {
    this.loading = true;
    const event = { first: 0, rows: this.totalRecords }
    this.vacationService.getListDocwesignConcluded(event, this.totalRecords).subscribe({
      next: async (res: any) => {    
      const listVacation = res.data.map(async (item:any) => {
        this.docConcluded.push({
          ...item
        })
       })
       
       await Promise.all(listVacation);
       this.totalRecords = res.totalRecords;
      },
      complete: () => {
       
        
      },
      error: (error) => {
        console.log(error);
      },
    }).add(() => {
       this.utilsService.exportExcell(this.avisoFilter, this.docConcluded);
       this.docConcluded = [];
       this.loading = false;
       this.listDocConcluded({ first: 0, rows: 10 });
    });
  }

  async modalEditdataEmploee(doc: any) {
    this.editForm.reset()
   
    const ferIini = await this.utilsService.formatDate(doc.fer_ini);
    const ferFim = await this.utilsService.formatDate(doc.fer_fim);
    const email = await this.utilsService.clearEmailNomeSpaceInWhite(doc.usuarios.dados_funcionarios.email);
    const nome = await this.utilsService.clearEmailNomeSpaceInWhite(doc.usuarios.dados_funcionarios.nome);
  
    this.doConcludedSelected = [doc]
   
    this.editForm.controls['nome'].setValue(nome);
    this.editForm.controls['ddd_celular'].setValue(doc.usuarios.dados_funcionarios.ddd_celular);
    this.editForm.controls['celular'].setValue(doc.usuarios.dados_funcionarios.celular);
    this.editForm.controls['email'].setValue(email);
    this.editForm.controls['fer_ini'].setValue(ferIini);
    this.editForm.controls['fer_fim'].setValue(ferFim);
    this.editForm.controls['dias_fer'].setValue(doc.dias_fer);
    this.visibleEdit = true
  }

  async editarCadastro() {
    if(this.editForm.status === 'INVALID'){
      this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: 'Todos os campos são obrigatório' });
    }else{
      this.loading = true
      const data = {
        ddd_celular: this.editForm.value.ddd_celular,
        celular: this.editForm.value.celular,
        email: this.editForm.value.email,
        nome: this.editForm.value.nome,
        fer_ini: await this.utilsService.formatDateSubtractDay(this.editForm.value.fer_ini),
        fer_fim: await this.utilsService.formatDateSubtractDay(this.editForm.value.fer_fim),
        funcId: this.doConcludedSelected[0].usuarios.dados_funcionarios.id,
        ferId: this.doConcludedSelected[0].id,
        dias_fer: this.editForm.value.dias_fer
      }
      this.api.updateEmployee(data).subscribe({
        next: (res: any) => {
          this.loading = false;
          this.messageService.add({
            severity: 'info',
            summary: 'Editar cadastro',
            detail: res.message,
          });
        },
        complete: () => {},
        error: (error) => { }
      }).add(async() => {

        let tokens = [];
        const filterTokens = this.doConcludedSelected.filter((item) => item.usuarios.notifications_tokens.length > 0);
        filterTokens.map((item: any) => {
          tokens.push(item.usuarios.notifications_tokens[0]?.token)
        });
    
        const sendToken: PayloadNotification = {
          tokens: tokens,
          notification: {
            title: 'Olá',
            body: 'Férias reenviada para assinatura eletronica, verificar com departamento pessoal'
          },
          data: {
            action: '/ferias',
            intent: 'FERIAS_INTENT'
          }
        }

      
        /*apos editar verificar se esta no prazo de assinatura eletronica or  manual  */
        const vacationFerIni = await this.utilsService.calcDatePendingVacationOrManual(data.fer_ini);
        if(vacationFerIni >= 30){
          const {usuario_fk, empresa_fk, id, doc_signature} = this.doConcludedSelected[0];
          const dataCanceletion = {
            usuario_fk: usuario_fk,
            empresa_fk: empresa_fk,
            ferias_cabecalho_fk: id,
            doc_signature: doc_signature,
            status: 'Canceled',
            manual_status: 'manual'
          }
          this.vacationService.excludedVacationwessignForManual(dataCanceletion, sendToken).subscribe({
            next: (res:any) => {
              this.messageService.add({
                severity: 'info',
                summary: 'Aviso Férias',
                detail: res.message,
              });
            },
            complete: () => {},
            error: () => {}
          })

        }else{

         
          const docmoverPending = await this.utilsService.dataSendWarinigVacation(this.doConcludedSelected);
          this.vacationService.excludedWesignForPending(docmoverPending, sendToken).subscribe({
            next: (res:any) => {
              this.messageService.add({
                severity: 'info',
                summary: 'Aviso Férias',
                detail: res.message,
              });
            },
            complete: () => {},
            error: () => {}
          })
        }

          this.loading = false
          this.doConcludedSelected = []
          this.editForm.reset()
          this.visibleEdit = false
          this.listDocConcluded({ first: 0, rows: 10 });
      })

    }

  }
}
