<div class="surface-0">
    <p-toast></p-toast>
    <app-menubar></app-menubar>
    <div class="grid">
      <div class="col-2" *ngIf="navbarVisible">
        <app-navbar></app-navbar>
      </div>
      <div class="col-10" [class.col-12]="!navbarVisible">
      <div class="card">
        <p-table
          #dt1
          [value]="vocationReceipt"
          dataKey="id"
          [rows]="10"
          [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10, 50]"
          [globalFilterFields]="['contador']"
          currentPageReportTemplate="Mostrando de {first} a {last} de um total de {totalRecords} registro(s)"
          [paginator]="true"
          [totalRecords]="totalRecords"
          [loading]="loading"
          scrollDirection="horizontal"
          [scrollable]="true"
          scrollHeight="600px"
          editMode="row"
          [lazy]="true"
          (onLazyLoad)="searchVacationsReceipt($event)"
          [(selection)]="selectedVocationReceipt">
          <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
              <div>
                Pesquisar Orgão
                <p-columnFilter 
                    [showAddButton]="false" 
                    matchMode="contains" 
                    [showOperator]="false"
                    [matchModeOptions]="[{label: 'Contém', value: 'contains'}]"
                    type="text" 
                    field="centro_custo"
                    display="menu">
                  </p-columnFilter>
              </div>
              <div>
                <button 
              type="button" 
              pButton pRipple 
              icon="pi pi-file-excel" 
              (click)="exportExcel()" 
              class="p-button-success mr-2"  
              pTooltip="XLS" tooltipPosition="bottom">
            </button>
                <p-button
                  type="button"
                  pRipple
                  icon="pi pi-upload"
                  class="p-button-success mr-2"
                  label="Enviar"
                  [badge]="
                  selectedVocationReceipt.length > 0
                      ? selectedVocationReceipt.length.toString()
                      : ''
                  "
                  (click)="uploadVacationsReceipt('receipt')"
                ></p-button>
            </div>

            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              
              <th style="min-width: 20rem;">
                Empresa
                <p-columnFilter 
                [showAddButton]="false"
                [showOperator]="false"
                [matchModeOptions]="[{label: 'Igual', value: 'equals'}]"
                type="text" 
                field="nome_fantasia" 
                display="menu" 
                class="ml-auto"
                ></p-columnFilter>
              </th>
              <th style="min-width: 25rem;">
                Funcionário
                <p-columnFilter 
                [showAddButton]="false"
                [showOperator]="false"
                [matchModeOptions]="[{label: 'Igual', value: 'equals'}]"
                type="text" 
                field="nome" 
                display="menu" 
                class="ml-auto"
                ></p-columnFilter>
              </th>
              <th style="min-width: 10rem;">
                CPF
                  <p-columnFilter
                    [showAddButton]="false"
                    [showOperator]="false"
                    type="text"
                    field="cpf"
                    display="menu"
                  ></p-columnFilter>
              </th>
              <th pSortableColumn="fer_ini">
                Início
                <p-columnFilter 
                [showAddButton]="false"
                [showOperator]="false"
                [matchModeOptions]="[{label: 'Igual', value: 'equals'}]"
                type="text" 
                field="fer_ini" 
                display="menu" 
                class="ml-auto"
                ></p-columnFilter>
              </th>

              <th pSortableColumn="fer_fim">
                Fim
                <p-columnFilter 
                [showAddButton]="false"
                [showOperator]="false"
                [matchModeOptions]="[{label: 'Igual', value: 'equals'}]"
                type="text" 
                field="fer_fim" 
                display="menu" 
                class="ml-auto"
                ></p-columnFilter>
              </th>

              <th pSortableColumn="warning_sended_at">
                Aviso
                <p-columnFilter 
                [showAddButton]="false"
                [showOperator]="false"
                [matchModeOptions]="[{label: 'Começa', value: 'startsWith'}]"
                type="text" 
                field="warning_sended_at" 
                display="menu" 
                class="ml-auto"
                ></p-columnFilter>
               
              </th>
              
              <th pSortableColumn="warning_acceppted_at" style="min-width: 8rem;">
                view
                <p-sortIcon field="warning_acceppted_at"></p-sortIcon>
               
              </th>
              <th>
                  Assinatura
              </th>

              <th pSortableColumn="contador" style="min-width: 7rem;">
               Cont<p-sortIcon field="contador"></p-sortIcon>
              </th>
              
              <th style="min-width: 8rem;">
                Recibo
                <p-columnFilter 
                [showAddButton]="false"
                [showOperator]="false"
                type="date" 
                field="periodo" 
                display="menu" 
                class="ml-auto"
                ></p-columnFilter>
              </th>
              <th></th>
              <th>PDF</th>
              <!-- <th>Can</th> -->
              <th>Noti</th>
              <th style="width: 4rem">

                <p-tableHeaderCheckbox (click)="filterSelectedreceiptTrue()"></p-tableHeaderCheckbox>
                
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-receipt let-ri="rowIndex">
            <tr  
            [ngStyle]="{'background-color': receipt.contador < '0' ? 'var(--red-50)' : (receipt.contador === '0' ? 'var(--yellow-50)' : 'var(--green-50)')}">
              
            
              <td style="min-width: 20rem;">{{ receipt.empresas.nome_fantasia }}</td>
              <td style="min-width: 25rem;">{{ receipt.usuarios.dados_funcionarios.nome }}</td>
              <td style="min-width: 10rem;">{{ receipt.usuarios.dados_funcionarios.cpf | cpf }}</td>
              <td>{{ receipt.fer_ini  }}</td>
              <td>{{ receipt.fer_fim }}</td>
              <!--data do nvio de aviso-->
              <td>{{ receipt.warning_sended_at| localdate }}</td>
              <td style="min-width: 7rem;"> {{ (receipt.warning_acceppted_at| localdate) || '-'}} </td>
              <td> {{ (receipt.doc_signature[0]?.created_at | localdate) || '-' }} </td>
              <td> {{receipt.contador}} </td>
              <td> {{(receipt.receipt_sended_at| localdate) || '-'}} </td>
              <td>
                <ng-template [ngIf]="receipt?.usuarios?.notifications_tokens?.length > 0" [ngIfElse]="notification">
                  <div class="userYesNotification"></div>
                </ng-template>
                
                <ng-template #notification>
                  <div class="userNotNotification"></div>
                </ng-template>
              </td>
              <td>
                <button
                pButton
                pRipple
                pTooltip="Aviso de férias" 
                tooltipPosition="left" 
                type="button"
                icon="pi pi-file-pdf"
                class="p-button-rounded p-button-info p-button-text p-button-raised"
                (click)="openPrintModal(receipt)"
              ></button>
              </td>
              <!-- <td>
                <button
                pButton
                pRipple
                pTooltip="Cancelamento de ferias e assinatura" 
                tooltipPosition="left" 
                type="button"
                icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text p-button-raised"
                (click)="showmodal(receipt)"
              ></button>
              </td> -->
              <td>
                <button
                pButton
                pRipple
                pTooltip="Enviar notificação"
                 tooltipPosition="left" 
                type="button"
                icon="pi pi-envelope"
                class="p-button-rounded p-button-primary p-button-text p-button-raised"
                (click)="sendNotification(receipt.usuarios.notifications_tokens)"
              ></button>
              </td>
              <td style="width: 4rem">
                <div *ngIf="receipt.sended_receipt; else elseBlock">
                  <p-tableCheckbox [value]="[]" [disabled]="receipt.sended_receipt">
                </p-tableCheckbox>
                </div>
                <ng-template #elseBlock>
                  <p-tableCheckbox [value]="receipt">
                </p-tableCheckbox>
                </ng-template>

              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">Não foi encontrada nenhuma categoria.</td>
            </tr>
          </ng-template>
          
        </p-table>
      </div>
      <div class="card flex justify-content-center gap-2">
        <p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>
      </div>
    </div>

    <!--modal-dialog-->
    <div>
      <p-dialog header="Informe o motivo da reprovação" [(visible)]="visible" [style]="{ width: '50vw' }">
        <div class="flex justify-content-center mb-2">
          <form [formGroup]="docForm">
            <textarea rows="5" cols="90" pInputTextarea formControlName="motivo_cancelamento"></textarea>
          </form>
        </div>
        <div class="card flex justify-content-evenly">
          <div>
            <p-button 
            label="Cancelar na Wesign" 
            [loading]="loading" 
            (click)="cancelDocWessign()">
          </p-button>
          </div>
          <div>
            <p-button 
              label="Cancelar por dados invalidos" 
              [loading]="loading" 
              (click)="cancelDocwesignDataInavalid()" 
              styleClass="p-button-warning">
            </p-button>
          </div>
        </div>
       
      </p-dialog>

      <!--modal dialog --print vacation -->
      <p-dialog header="Imprimir Aviso ou Recibo" [(visible)]="printModal" [style]="{width: '18vw'}">
        <div class="flex justify-content-evenly">
          <button
          pButton
          pRipple
          [loading]="loadingButtonAviso"
          type="button"
          label="Aviso"
          icon="pi pi-file-pdf"
          class="p-button-info p-button-text p-button-raised"
          (click)="printVacationWarning()"
        ></button>

          <button
          pButton
          pRipple
          [loading]="loadingButtonRecibo"
          type="button"
          label="Recibo"
          icon="pi pi-file-pdf"
          class="p-button-info p-button-text p-button-raised"
          (click)="printVacationReceipt()"
        ></button>
        </div>
        <ng-template pTemplate="footer">
        </ng-template>
    </p-dialog>
    </div>
  </div>
  