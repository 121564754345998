<div class="surface-20">
  <p-toast></p-toast>
  <app-menubar></app-menubar>

  <div class="grid">
    <div class="col-2 bg-secondary" *ngIf="navbarVisible">
      <app-navbar></app-navbar>
    </div>

    <div class="col-10" [class.col-12]="!navbarVisible">
      <div class="card">
        <p-table #dt1 [value]="vacations" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10, 50, 100]"
          currentPageReportTemplate="Mostrando de {first} a {last} de um total de {totalRecords} registro(s)"
          [paginator]="true" [loading]="loading" [totalRecords]="totalRecords" scrollDirection="horizontal"
          [scrollable]="true" scrollHeight="600px" editMode="row" [lazy]="true"
          [globalFilterFields]="['vacation.periodo']" (onLazyLoad)="searchVacations($event)"
          [(selection)]="selectedVacations">
          <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
              <div>
                Pesquisar Orgão
                <p-columnFilter [showAddButton]="false" matchMode="contains" [showOperator]="false"
                  [matchModeOptions]="[{label: 'Contém', value: 'contains'}]" type="text" field="centro_custo"
                  display="menu">
                </p-columnFilter>
              </div>

              <div>
                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                  class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom">
                </button>
                <p-button type="button" pRipple pTooltip="Envio de Aaviso de férias" tooltipPosition="left"
                  icon="pi pi-pencil" class="p-button-success mr-2" label="Enviar" [badge]="
                  selectedVacations.length > 0
                    ? selectedVacations.length.toString()
                    : ''
                " (click)="uploadVacations('warning')"></p-button>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>

              <th style="min-width: 20rem;">
                Empresa
                <p-columnFilter [showAddButton]="false" [showOperator]="false"
                  [matchModeOptions]="[{label: 'Contém', value: 'contains'}]" type="text" field="nome_fantasia"
                  display="menu">
                </p-columnFilter>
              </th>
              <th style="min-width: 25rem;">
                Funcionário
                <p-columnFilter [showAddButton]="false" [showOperator]="false"
                  [matchModeOptions]="[{label: 'Contém', value: 'contains'}]" type="text" field="nome" display="menu">
                </p-columnFilter>
              </th>
              <th style="min-width: 10rem;">
                CPF
                <p-columnFilter [showAddButton]="false" [showOperator]="false"
                  [matchModeOptions]="[{label: 'Igual', value: 'equals'}]" type="text" field="cpf" display="menu">
                </p-columnFilter>
              </th>
              <th>
                Matricula
                <p-columnFilter [showAddButton]="false" [showOperator]="false"
                  [matchModeOptions]="[{label: 'Igual', value: 'equals'}]" type="text" field="matricula" display="menu">
                </p-columnFilter>
              </th>
              <th pSortableColumn="fer_ini">
                Início
                <p-columnFilter [showAddButton]="false" [showOperator]="false"
                  [matchModeOptions]="[{label: 'Igual', value: 'equals'}]" type="text" field="fer_ini" display="menu"
                  class="ml-auto"></p-columnFilter>
              </th>
              <th pSortableColumn="fer_fim">
                Fim
                <p-columnFilter [showAddButton]="false" [showOperator]="false" type="text"
                  [matchModeOptions]="[{label: 'Igual', value: 'equals'}]" field="fer_fim" display="menu"
                  class="ml-auto"></p-columnFilter>
              </th>
              <th pSortableColumn="periodo">
                Periodo
                <p-columnFilter [showAddButton]="false" [showOperator]="false" type="text" field="periodo"
                  display="menu"></p-columnFilter>
              </th>
              <th></th>
              <th>PDF/CANCEL</th>
              <th style="width: 4rem">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-vacation let-ri="rowIndex">
            <tr>
              <td style="min-width: 20rem;">{{ vacation.empresas.nome_fantasia }}</td>
              <td style="min-width: 25rem;">{{ vacation.usuarios.dados_funcionarios.nome }}</td>
              <td style="min-width: 10rem;">{{ vacation.usuarios.dados_funcionarios.cpf | cpf }}</td>
              <td>{{ vacation.usuarios.dados_funcionarios.matricula}}</td>
              <td>{{ vacation.fer_ini }}</td>
              <td>{{ vacation.fer_fim }}</td>
              <td>
                {{vacation.mes_movimento }}
              </td>
              <td>
                <ng-template [ngIf]="vacation?.usuarios?.notifications_tokens?.length > 0" [ngIfElse]="notification">
                  <div class="userYesNotification"></div>
                </ng-template>

                <ng-template #notification>
                  <div class="userNotNotification"></div>
                </ng-template>
              </td>
              <td class="flex justify-content-between flex align-items-center">
                <button pButton pRipple pTooltip="Aviso de férias" tooltipPosition="left" type="button"
                  icon="pi pi-file-pdf" class="p-button-rounded p-button-info p-button-text p-button-raised"
                  (click)="printVacationWarning(vacation)"></button>

                  <button pButton pRipple pTooltip="Editar dados" tooltipPosition="left" type="button"
                  icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-text p-button-raised"
                  (click)="modalEditdataEmploee(vacation)"></button>

                <button pButton pRipple pTooltip="Cancelamento de férias" tooltipPosition="left" type="button"
                  icon="pi pi-times" class="p-button-rounded p-button-warning p-button-text p-button-raised"
                  (click)="cancelWarinigBeforeShipping(vacation)"></button>
              </td>
              <td style="width: 4rem">
                <p-tableCheckbox [value]="vacation"></p-tableCheckbox>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">Não foi encontrada nenhuma categoria.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="card flex justify-content-center gap-2">
        <p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>
      </div>
    </div>
  </div>
  <!--MODAL--EDITA---DATA-->
  
    <p-dialog
     header="{{selectedVacations[0]?.usuarios?.dados_funcionarios?.nome}}" 
     [(visible)]="visibleEdit"
     [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" 
     [style]="{width: '30vw'}">
      <form [formGroup]="editForm">
        <div class="grid">
          <div class="p-inputgroup field flex">
            <div class="col-2">
              <input
              type="text"
              class="flex w-full"
              pInputText
              placeholder="ddd"
              maxlength="2"
              formControlName="ddd_celular"
            />
            </div>
            <div class="col-10">
              <input
                type="text"
                class="flex w-full"
                pInputText
                maxlength="9"
                minlength="9"
                placeholder="Celular"
                formControlName="celular"
              />
              
            </div>
          </div>

          <div class="p-inputgroup field flex">
            <div class="col-12">
              <input
                type="text"
                class="flex w-full"
                pInputText
                placeholder="Email"
                formControlName="email"
              />
              
            </div>
          </div>

          <div class="p-inputgroup field flex">
            <div class="col-12">
              <input
                type="text"
                class="flex w-full"
                pInputText
                placeholder="Nome"
                formControlName="nome"
              />
              
            </div>
          </div>

          <div class="p-inputgroup field flex">
            <div class="col-6">
              <label for="buttondisplay" class="font-bold block mb-2"> Inicio das férias </label>
              <p-calendar 
                formControlName="fer_ini" 
                dateFormat="dd/mm/yy"
                [showIcon]="true" 
                inputId="buttondisplay" 
                [showOnFocus]="false" >
            </p-calendar>
            </div>
            <div class="col-6">
              <label for="buttondisplay" class="font-bold block mb-2"> Fim das férias </label>
              <p-calendar 
                formControlName="fer_fim" 
                dateFormat="dd/mm/yy"
                [showIcon]="true" 
                inputId="buttondisplay" 
                [showOnFocus]="false" >
            </p-calendar>
              
            </div>
          </div>
        </div>
      </form>
      <ng-template pTemplate="footer">
          <p-button icon="pi pi-check"  
          (click)="editarCadastro()" 
          [disabled]="editForm.status === 'INVALID' ? true : false"
          label="EDITAR"
          styleClass="p-button-text">
        </p-button>
      </ng-template>
  </p-dialog>

  <!--progressbar--send--vacation-->
  <p-dialog header="Envio de aviso de férias" 
  [(visible)]="send" 
  [modal]="true" 
  [style]="{width: '50vw'}" 
  [maximizable]="false"
  [closable]="false"
  [draggable]="false" 
  [resizable]="false">
    <p-progressBar [value]="progress"></p-progressBar>
    <p>A(s) férias foram enviadas para o(s) funcionário(s) selecionados. Por favor aguardar o processo !!!</p>
        <ng-template pTemplate="footer"></ng-template>
</p-dialog>
</div>