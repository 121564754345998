import { Injectable } from '@angular/core';
import * as moment from 'moment'
import { jsPDF } from "jspdf";
import * as FileSaver from 'file-saver';
import { HttpClient } from '@angular/common/http';
import heic2any from "heic2any";


@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private http: HttpClient) { }

  limparCPF(cpf: string): string {
    // Remove pontos (.)
    let cpfSemPontos = cpf?.replace(/\./g, '');

    // Remove traço (-)
    let cpfLimpo = cpfSemPontos?.replace(/-/g, '');

    return cpfLimpo;
  }
  limparTelefone(telefone: string): string {
    // Remove espaços em branco
    let telefoneSemEspacos = telefone.replace(/\s/g, '');

    // Remove parênteses () e traços (-)
    let telefoneLimpo = telefoneSemEspacos.replace(/[()\-]/g, '');

    return telefoneLimpo;
  }
  limparCNPJ(cnpj: string): string {
    // Remove pontos (.)
    let cnpjSemPontos = cnpj.replace(/\./g, '');

    // Remove barras (/)
    let cnpjSemBarras = cnpjSemPontos.replace(/\//g, '');

    // Remove traços (-)
    let cnpjLimpo = cnpjSemBarras.replace(/-/g, '');

    return cnpjLimpo;
  }
  public gerarNumeroAleatorioEmR(): number {
    const min = 0;
    const max = 2500;

    // Gera um número aleatório entre min e max
    const valor = Math.random() * (max - min) + min;

    // Arredonda o número para duas casas decimais
    const valorArredondado = Math.round(valor * 100) / 100;

    return valorArredondado;
  }

  public async countDayReceiptVocation(ferIni: any) {

    const currentDate = moment.utc(new Date()).local().format('DD/MM/YYYY');
    const dataInicial = moment(currentDate, 'DD/MM/YYYY')
    const dataFinal = moment(ferIni, 'DD/MM/YYYY');

    let diferencaEmDias = dataFinal.diff(dataInicial, 'days');
    if (diferencaEmDias >= 0) {
      return diferencaEmDias - 2;
    } else {
      return diferencaEmDias
    }

  }

  async ptintDocWesignConcluded(docWesign: any, nome:string) {
    const binaryData = atob(docWesign.bytes);
    const dataArray = new Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      dataArray[i] = binaryData.charCodeAt(i);
    }
    const uint8Array = new Uint8Array(dataArray);
    const blob = new Blob([uint8Array], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.download = `${nome}.pdf`;
    link.href = window.URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  async parseStringdateFormatMonthYearDay(date: any) {
    const parseDate = moment(date, 'YYYY/MM/DD').format('DD/MM/YYYY');
    const customDate: any = moment(parseDate, 'DD/MM/YYYY').toDate();
    return customDate
  }


  /* print pdf */
  async printLayoutPdfReciboFerias(res:any) {
    const byteCharacters = atob(res.body);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const blob = new Blob([new Uint8Array(byteNumbers)], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = res.document;
    a.click();
  }

  async  convertImageToBase64(url, format = 'jpeg') {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
  
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
  
        try {
          const base64Image = canvas.toDataURL(`image/${format}`);
          resolve(base64Image);
        } catch (error) {
          reject(`Error converting image to base64: ${error.message}`);
        }
      };
  
      img.onerror = (error:any) => {
        reject(`Error loading image: ${error.message}`);
      };
  
      img.src = url;
    });
  }
  

  async formatImgForPdf(base64Image:any, ferias:any){
  const name = ferias.usuarios.dados_funcionarios.nome;
  const removeSpace = name.trim()
  const pdf = new jsPDF({unit:'mm', format:'a4'});
  const marginLeft = 10;
  const marginRight = 10;
  const marginTop = 10;
  const marginBottom = 10;
  const width = pdf.internal.pageSize.getWidth() - marginLeft - marginRight;
  const height = pdf.internal.pageSize.getHeight() - marginTop - marginBottom;
  pdf.addImage(base64Image, 'JPEG', marginLeft, marginTop, width, height);
  const pdfBlob = pdf.output('blob');
  const pdfUrl = URL.createObjectURL(pdfBlob);

  const link = document.createElement('a');
  link.href = pdfUrl;
  link.download = `${removeSpace}_`+'.pdf';
  link.click();
    
  URL.revokeObjectURL(pdfUrl);
  }

   

  async  downloadPdfImgToFromHeic(url:string, atestado:any) {
    const name = atestado.usuarios.dados_funcionarios.nome;
    const removeSpace = name.trim();
  
    try {
      // Buscar o arquivo HEIC e converter para PNG
      const response = await fetch(url);
      const blob = await response.blob();
      const conversionResult:any = await heic2any({
        blob,
        toType: "image/png",
        gifInterval: 0.3,
      });
  
      // Criação do PDF utilizando a biblioteca jsPDF
    
      const pdf:any = new jsPDF();

      // Adicionar a imagem PNG convertida ao PDF
      const reader = new FileReader();
      reader.onload = function (event) {
        const imgData = event.target.result;
        const marginLeft = 10;
        const marginRight = 10;
        const marginTop = 10;
        const marginBottom = 10;
        const width = pdf.internal.pageSize.getWidth() - marginLeft - marginRight;
        const height = pdf.internal.pageSize.getHeight() - marginTop - marginBottom;
        pdf.addImage(imgData, 'PNG', 10, 10, width, height); // Ajustar posição e tamanho conforme necessário
  
        // Baixar o PDF
        pdf.save(`${removeSpace}_atestado.pdf`);
      };
      reader.readAsDataURL(conversionResult);
    } catch (error) {
      console.error('Erro ao processar o arquivo:', error);
    }
  }



  async getAtestadoAws(ferias:any,  url:string){
    const name = ferias.usuarios.dados_funcionarios.nome;
    const removeSpace = name.trim()
    let filename = `${removeSpace}_`+'.pdf';
    
   return fetch(url)
      .then(response => response.blob())
      .then(blob => {
      console.log(blob);
      const {size} = blob 
      if(size > 0){
        let downloadLink = document.createElement('a');
        let blobUrl = window.URL.createObjectURL(blob);
        downloadLink.href = blobUrl;
        downloadLink.download = filename;   
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(blobUrl);
        return {message: "Doc encontrado", status: true}
      }else{
       return {message: "Padrão de documento recebido, não é suportado pelo sistema! Por favor solicite o reenvio.", status: false}
      }
  });
  }


  
  async countMonthEmploye(date:string){
    let dateFormat = await this.parseStringdateFormatMonthYearDay(date);
    let dateStart = moment(dateFormat);

    let finalDate = moment.utc();
    let result = finalDate.diff(dateStart, 'months');
    
    return result
  }

  async verifyStatus(status:string){
    switch (status) {
      case ' ':
          return 'ATIVO';
      case 'F':
          return 'FERIAS';
      case 'D':
          return 'DEMITIDO'
      default:
        return 'Sem status'
    }
  }

  async tipoContrato(cod:string){

    switch (cod) {
      case '101':
        return 'Efetivo indeterminado';

      case '102':
        return 'Empregado Rural';

      case '103':
        return 'Empregado Aprendiz';
      
      case '104':
        return 'Empregado Doméstico';
      
      case '105':
        return 'Empregado Contrato Firmado';
      
      case '106':
        return 'Trabalhador Temporário Prazo Determinado';
      
      case '107':
        return  'Empregado Verde e Amaraelo';
      
      case '108':
        return 'Empregado Verde e Amarelo';
      
      case '111':
        return  'Contrato Intermitente';
      
      case '201':
        return  'Trabalhador aAulso';

      case '202':
        return 'Trabalhador Avulso não Portuario';

      case '301':
        return 'Servidor Ṕublico - ELetivo';

      case '302':
        return 'Servidor publico - Comissionario';

      case '303':
        return 'Servidor Ṕublico - Agente';
        
      case '305':
        return 'Servidor Ṕublico - Inicado';

      case '306':
        return 'Servidor Ṕublico - Temporario';

      case '401':
        return 'Diregente Sindical';

      case '410':
        return 'Trabalhador Cedido';

      case '701':
        return 'Contribuinte Geral';
      
      case '711':
        return 'Contrib individual  Autônomo';

      case '721':
        return 'Contrib Individual Diretor não Empregado';
      
      case '722':
        return 'Contrib Individual Diretor não Empregado';

      case '723':
        return 'Empresários Sócios';
      
      case '734':
        return  'Prestador de Serviço por Intermédio';
      
      case '738':
        return 'Contrib Individual Cooperador Filiado';
      
      case '741':
        return 'Contrib Micro Empreendedor Indidual PJ';
      
      case '751':
        return 'Contribu Individual Aposentado';
      
      case '761':
        return 'Contrib Individual Associado a Direção';
      
      case '771':
        return 'Contrib Individual  Concelho Tutelar';
      
      case  '781':
      return 'Ministro de Confissão Religiosa';

      case '901':
        return 'Estagiário';

      case  '902':
        return 'Médico Residente';


        default:
          return 'Sem Descrição';
    }
  }


  async filterVacation(filters:any){
  const builtFilters: any = {};
  Object.keys(filters).forEach(key => {
    const filterValues = filters[key];
      const builtFilterValues = filterValues.map((filter: any) => ({
        value: filter.value,
        matchMode: filter.matchMode || 'startsWith', // Modo de correspondência padrão
        operator: filter.operator || 'and' // Operador padrão
      }));
      builtFilters[key] = builtFilterValues;
  });
  
  return builtFilters;
  }

  async filterAvisoExportexcel(avisoFilter:any[] ,dados:any[]){


    const filteredData = dados.map(item => {
      let filteredItem = [];
      avisoFilter.forEach(async (filter) => {
          
           if (item?.empresas?.nome_fantasia !== undefined) {
              filteredItem['NOME FANTASIA'] = item.empresas.nome_fantasia;
            }
            
            if(item?.usuarios.dados_funcionarios?.nome !== undefined){
              filteredItem['NOME FUNCIONARIO'] = item.usuarios.dados_funcionarios.nome;
            }

             
            if(item?.usuarios.dados_funcionarios?.cpf !== undefined){
              filteredItem['CPF'] = item.usuarios.dados_funcionarios.cpf;
            }

              
            if(item?.usuarios.dados_funcionarios?.matricula !== undefined){
              filteredItem['MATRICULA'] = item.usuarios.dados_funcionarios.matricula;
            }

            if(item.usuarios.dados_funcionarios?.num_centro_custo !== undefined){
              filteredItem['LOCAÇÂO'] = item.usuarios.dados_funcionarios.num_centro_custo;
            }

            if(item?.fer_ini !== undefined){
              filteredItem['INICIO FERIAS'] = item?.fer_ini;
            }

            if(item?.fer_fim!== undefined){
              filteredItem['FIM FERIAS'] = item?.fer_fim;
            }

            if(item?.ferias_cabecalhos?.fer_ini !== undefined){
              filteredItem['INICIO FERIAS'] = item?.ferias_cabecalhos?.fer_ini;
            }

            if(item?.ferias_cabecalhos?.fer_fim !== undefined){
              filteredItem['FIM FERIAS'] = item?.ferias_cabecalhos?.fer_fim;
            }

            if(item?.mes_movimento !== undefined){
              filteredItem['MES MOVIMENTO'] = item?.mes_movimento;
            }

            if(item?.usuarios?.notifications_tokens?.length > 0){
              filteredItem['APP'] = 'SIM';
            }

            if(item?.usuarios?.notifications_tokens?.length === 0){
              filteredItem['APP'] = 'NÂO';
            }

            //data do envio do aviso
            if(item?.warning_sended_at !== undefined){
              filteredItem['DATA ENVIO AVISO'] = await this.parseStringdateFormatMonthYearDay(item?.warning_sended_at);
            }

            if(item.created_at !== undefined){
              filteredItem['DATA ENVIO AVISO'] =  await this.parseStringdateFormatMonthYearDay(item?.created_at)
            }

            if(item?.ferias_cabecalhos?.warning_sended_at !== undefined){
              filteredItem['DATA ENVIO AVISO'] = await this.parseStringdateFormatMonthYearDay(item?.ferias_cabecalhos?.warning_sended_at);
            }

    
            
            
          });
      
      return filteredItem;
    });
  
    return filteredData;
  }


  async exportExcell(avisoFilter:any[], dados:any[]){
    const aviso:any = await this.filterAvisoExportexcel(avisoFilter, dados);
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(aviso);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "aviso");
      
  });

  }
  

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  async formatDate(date:string){
    const currentDate = moment.utc(date, 'DD/MM/YYYY', true).add('1', 'd').local().toDate();
    return currentDate
  }

  async formatDateSubtractDay(date:string){
    const currentDate = moment.utc(date, 'DD/MM/YYYY', true).local().format('DD/MM/YYYY');
    return currentDate
  }

  async clearEmailNomeSpaceInWhite(str:string){
    return str.replace(/^\s+|\s+$/g, '');
  }

  async  dataSendWarinigVacation(data:any[]){

    const doc = [];
    data.map((item) => {
    doc.push({
           ...item.doc_signature[0],
          created_at: item.created_at,
          update_at: item.update_at,
          type_doc: item.type_doc,
          
          aceite_warning: false,
          aceite_receipt: item.aceite_receipt,
          sended_receipt: item.sended_receipt,
          sended_warning: item.sended_warning,

          warning_acceppted_at: item.warning_acceppted_at,
          receipt_acceppted_at: item.receipt_acceppted_at,
          warning_sended_at: item.warning_sended_at,
          receipt_sended_at: item.receipt_sended_at,

          fer_ini: item.fer_ini,
          fer_fim: item.fer_fim,
          base_ini: item.base_ini,
          base_fim: item.base_fim,
          matricula: item.matricula,

          empresas: { 
            cnpj: item.empresas.cnpj, 
            nome_fantasia: item.empresas.nome_fantasia 
          },
          usuarios: { 
            notifications_tokens: item.usuarios.notifications_tokens, 
            dados_funcionarios: item.usuarios.dados_funcionarios 
          },
          fer_id: item.id,
          manual_status: '-',
          motivo_cancelamento: 'Documento cancelado pois o Prazo de Assinatura Digital foi expirado! Entre em contato com sua empresa para assinar manualmente.',
          wesign: { id_doc: item.doc_signature[0].id_doc }
     })    
    });
    return doc
  }

  async calcDatePendingVacationOrManual(fer_ini: string){

    const currentDate = moment()
    const ferIni = moment(fer_ini, 'DD/MM/YYYY')
    const diffDays = Math.abs(currentDate.diff(ferIni, 'days'));
    return diffDays;
  }
}
