import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LazyLoadEvent, MessageService, ConfirmationService, ConfirmEventType } from 'primeng/api';
import { DocPendingWesign } from 'src/app/models/docPendingWesign';
import { ApiService } from 'src/app/services/api.service';
import { AwsService } from 'src/app/services/aws/aws.service';
import { VacationService } from 'src/app/services/gpe/vacation.service';
import { NavbarService } from 'src/app/services/navbar-service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-wesign-pending',
  templateUrl: './wesign-manual.component.html',
  styleUrls: ['./wesign-manual.component.scss']
})
export class WesignManualComponent implements OnInit {

  docManual: DocPendingWesign[] = [];
  docManualSelected: any[] = [];
  loading: boolean = false;
  loadingManual: boolean = false;
  loadingInvalid: boolean = false;
  visible: boolean = false;
  public navbarVisible: boolean;
  public docForm: FormGroup;
  docCancel = [];
  totalRecords: number = 0;
  loadingButtonAviso: boolean = false;
  value: number;
  uploadedFiles: any[] = [];
  avisoFilter: any[] = [];
  editForm: FormGroup;
  visibleEdit: boolean = false;

  constructor(
    private vacationService: VacationService,
    private messageService: MessageService,
    public formBuilder: FormBuilder,
    public utilsservices: UtilsService,
    private navbarService: NavbarService,
    private confirmationService: ConfirmationService,
    private awsService: AwsService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this.editForm = this.fb.group({
      ddd_celular: ['', [Validators.required, Validators.maxLength(2), Validators.minLength(2)]],
      celular: ['', [Validators.required, Validators.maxLength(9), Validators.minLength(9)]],
      email: ['', [Validators.required, Validators.email]],
      nome: ['', [Validators.required]],
      fer_ini: ['', Validators.required],
      fer_fim: ['', Validators.required],
      dias_fer: ['', Validators.required]
    });

    this.docForm = this.formBuilder.group({
      motivo_cancelamento: ['', [Validators.required]],
    });
    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });

    this.avisoFilter = [
      { nome_fantasia: 'nome_fantasia' },
      { nome: 'nome' },
      { cpf: 'cpf' },
      { matricula: 'matricula' },
      { fer_ini: 'fer_ini' },
      { fer_fim: 'fer_fim' },
      { mes_movimento: 'mes_movimento' },
      { created_at: 'created_at' },
      { num_centro_custo: 'num_centro_custo' }
    ];
  }

  listDocPendingWesign(event: LazyLoadEvent) {
    this.loading = true;
    this.docManual = [];

    this.vacationService.getListDocwesignManual(event, this.totalRecords).subscribe({
      next: async (res: any) => {
        const promisPending = res.fer.map(async (item: any) => {
          this.totalRecords = res.totalRecords;
          this.docManual.push({
            ...item
          })
        })
        await Promise.all(promisPending)
      },
      complete: () => { this.loading = false },
      error: (e) => { }
    })
  }

  async modalEditdataEmploee(doc: any) {
    const ferIini = await this.utilsService.formatDate(doc.fer_ini);
    const ferFim = await this.utilsService.formatDate(doc.fer_fim);
    const email = await this.utilsService.clearEmailNomeSpaceInWhite(doc.usuarios.dados_funcionarios.email);
    const nome = await this.utilsService.clearEmailNomeSpaceInWhite(doc.usuarios.dados_funcionarios.nome);

    this.editForm.reset()
    this.docManualSelected = [doc]
    this.editForm.controls['nome'].setValue(nome);
    this.editForm.controls['ddd_celular'].setValue(doc.usuarios.dados_funcionarios.ddd_celular);
    this.editForm.controls['celular'].setValue(doc.usuarios.dados_funcionarios.celular);
    this.editForm.controls['email'].setValue(email);
    this.editForm.controls['fer_ini'].setValue(ferIini);
    this.editForm.controls['fer_fim'].setValue(ferFim);
    this.editForm.controls['dias_fer'].setValue(doc.dias_fer);
    this.visibleEdit = true
  }



  async printVacationWarning(doc: any) {
    this.loading = true;
    const docPrint = { ...doc }
    this.vacationService.getLayoutPdfAvisoFerias([docPrint]).subscribe({
      next: (res: any) => {
        const blob = new Blob([new Uint8Array(res.pdfBUffer.data)], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = res.document;
        a.click();
      },
      complete: () => {
        this.loading = false;

      },
      error: () => { }
    });

  }

  signatureDocManual(doc: any) {
    this.docManualSelected = [];
    this.docManualSelected = [doc]
    if (this.docManualSelected.length > 0) {
      this.visible = true
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Assinatuta Manual',
        detail: 'Por favor selecionar um item',
      });
    }
  }

  onUpload(event: any) {
    this.visible = true;
    this.cancelSignatureDocManual(event);
  }


  async cancelSignatureDocManual(event: any) {
    this.docManualSelected.map((item) => {
      this.docCancel.push({
        empresa_fk: item.empresa_fk,
        usuario_fk: item.usuario_fk,
        fer_id: item.id,
      })
    })

    this.loading = true;
    this.confirmationService.confirm({
      message: 'Voê deseja assinar todos os itens manualmente?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.value = 60
        this.vacationService.signatureDocManual(this.docManualSelected, this.docForm.value.motivo_cancelamento).subscribe({
          next: (res: any) => {
            this.value = 80
            this.messageService.add({
              severity: 'info',
              summary: 'Assinatura Manual',
              detail: res.message,
            });
            this.awsService.uploadDocManualSignature(this.docCancel, event).subscribe({
              next: (res: any) => {
                this.value = 100
                this.messageService.add({
                  severity: 'info',
                  summary: 'Assinatura Manual',
                  detail: 'Upload relaizado com sucesso!',
                });
              },
              complete: () => {
                this.value = 0
                this.docCancel = [];
                this.loading = false;
                this.docManualSelected = [];
                this.visible = false;
                this.docForm.reset();
                this.listDocPendingWesign({ first: 0, rows: 10 });
              },
            })
          },

          error: (err) => {
            this.messageService.add({
              severity: 'info',
              summary: 'Assinatura Manual',
              detail: 'Error ao fazer upload',
            });
          }
        })
      },
      reject: (type: any) => {
        this.loading = false;
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Procedimento cancelado' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Procedimento cancelado' });
            break;
        }
      },
      acceptLabel: 'Confirmar'
    });
  }


  async exportExcel() {
    this.loading = true;
    this.docManual = [];
    const event = { first: 0, rows: this.totalRecords }
    this.vacationService.getListDocwesignManual(event, this.totalRecords).subscribe({
      next: async (res: any) => {
        const listVacation = res.fer.map(async (item: any) => {
          this.docManual.push({
            ...item
          })
        })

        await Promise.all(listVacation);
        this.totalRecords = res.totalRecords;
      },
      complete: () => {
      },
      error: (error) => {
        console.log(error);
      },
    }).add(() => {
      this.utilsService.exportExcell(this.avisoFilter, this.docManual);
      this.docManual = [];
      this.loading = false;
      this.listDocPendingWesign({ first: 0, rows: 10 });
    });
  }

  async cancelWarinigBeforeShipping(vacation: any) {
    const { id, usuarios } = vacation;

    this.confirmationService.confirm({
      message: `Você deseja proseguir com o cancelamento de aviso do Sr(a) ${usuarios.dados_funcionarios.nome}`,
      header: 'Deletar aviso',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true;
        this.vacationService.cancelWarningBerforeShipping(id).subscribe({
          next: (res: any) => {
            this.messageService.add({
              severity: 'info',
              summary: 'Recibo',
              detail: res.message,
            });
          },
          complete: () => {
            this.loading = false;
            this.listDocPendingWesign({ first: 0, rows: 10 });
          },
          error: (erro) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Recibo',
              detail: 'Não foi possivel  cancelar o aviso',
            });
          }
        })
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: 'Procedimento foi abortado' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: 'Procedimento foi abortado' });
            break;
        }
      }
    });
}

  async editarCadastro() {
    if (this.editForm.status === 'INVALID') {
      this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: 'Todos os são campos obrigatório' });
    } else {
      this.loading = true
      const data = {
        ddd_celular: this.editForm.value.ddd_celular,
        celular: this.editForm.value.celular,
        email: this.editForm.value.email,
        nome: this.editForm.value.nome,
        fer_ini: await this.utilsService.formatDateSubtractDay(this.editForm.value.fer_ini),
        fer_fim: await this.utilsService.formatDateSubtractDay(this.editForm.value.fer_fim),
        funcId: this.docManualSelected[0].usuarios.dados_funcionarios.id,
        ferId: this.docManualSelected[0].id,
        dias_fer: this.editForm.value.dias_fer
      }

      this.api.updateEmployee(data).subscribe({
        next: (res: any) => {
          this.loading = false;
          this.messageService.add({
            severity: 'info',
            summary: 'Editar cadastro',
            detail: res.message,
          });
        },
        complete: () => {
          this.loading = false
          this.docManualSelected = []
          this.visibleEdit = false
          this.editForm.reset()
          this.docManual = [];
          this.listDocPendingWesign({ first: 0, rows: 10 });
        },
        error: (error) => { }
      })

    }
  }


}
