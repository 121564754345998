<div class="surface-0">
    <p-toast></p-toast>
    <div class="grid">
        <div class="col-12">
            <app-menubar></app-menubar>
        </div>

        <div class="col-2" *ngIf="navbarVisible">
            <app-navbar></app-navbar>
        </div>

        <!--DASBOARD RHAPP-->
        <div *ngIf="permission?.description !== 'comercial'" class="col-10" [class.col-12]="!navbarVisible">
            <div class="col-12">
                <div class="grid">
                    <div class="col-12 m-2">
                        <!--companies-->
                        <p-multiSelect [options]="companies" [(ngModel)]="companiesSelected"
                            defaultLabel="Selecione a empresa" selectedItemsLabel="{0} items selected"
                            optionLabel="name">
                        </p-multiSelect>

                        <!--month-reference mesMovimentoStart-->
                        <p-calendar class="ml-2" [(ngModel)]="mesMovimentostart" placeholder="Mes movimento inicio"
                            view="month" dateFormat="mm/y" [readonlyInput]="true" inputId="monthpicker">
                        </p-calendar>


                        <!--month-reference mesMovimentoEnd-->
                        <p-calendar class="ml-2" placeholder="Mes movimnto fim" [(ngModel)]="mesMovimentoEnd"
                            view="month" dateFormat="mm/y" [readonlyInput]="true" inputId="monthpicker">
                        </p-calendar>
                        <!--searh-->
                        <button pButton type="button" icon="pi pi-search" class="ml-2"
                            (click)="filtersDashboards()"></button>

                        <!--refresh-->
                        <button pButton type="button" icon="pi pi-refresh" class="p-button-success ml-2"
                            (click)="refreshDashbopard()"></button>

                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="grid">

                    <div class="col-3 flex-collumn">
                        <div class="col-12">
                            <!--Ativos-->
                            <div class="card flex justify-content-between bg-green-600">
                                <div class="flex align-items-center justify-content-start">
                                    <img class="surface-border m-2" src="assets/img/Ativos.png" alt="Perfil" width="70"
                                        height="60">
                                </div>
                                <div
                                    class="flex-collumn align-items-end justify-content-end  m-2 text-center text-white">
                                    <span class="font-bold text-2xl">{{employees[0]?.ativo | divideBy:1000 |
                                        number:'1.3-3' }}</span>
                                    <p>Funcionários Ativos</p>
                                </div>
                            </div>

                            <!--Ferias-->
                            <div class="card mt-3 flex justify-content-between bg-blue-500">
                                <div class="flex align-items-center justify-content-start">
                                    <img class="surface-border  m-2" src="assets/img/Ferias.png" alt="Perfil" width="70"
                                        height="60">
                                </div>
                                <div
                                    class="flex-collumn align-items-end justify-content-end  m-2 text-center text-white">
                                    <span class="font-bold text-2xl">{{employees[0]?.ferias | divideBy:1000 |
                                        number:'1.3-3'}}</span>
                                    <p>Funcionários férias</p>
                                </div>
                            </div>

                            <!--Afastados-->
                            <div class="card mt-3 flex justify-content-between bg-yellow-400">
                                <div class="flex align-items-center justify-content-start">
                                    <img class="surface-border m-2" src="assets/img/Afastados.png" alt="Perfil"
                                        width="70" height="60">
                                </div>
                                <div
                                    class="flex-collumn align-items-end justify-content-end  m-2 text-center text-white">
                                    <span class="font-bold text-2xl">{{employees[0]?.afastado | divideBy:1000 |
                                        number:'1.3-3'}}</span>
                                    <p>Funcionários Afastados</p>
                                </div>
                            </div>

                            <!--Demitidos-->
                            <div class="card mt-3 flex justify-content-between bg-red-400">
                                <div class="flex align-items-center justify-content-start">
                                    <img class="surface-border  m-2" src="assets/img/Demitidos.png" alt="Perfil"
                                        width="70" height="60">
                                </div>
                                <div
                                    class="flex-collumn align-items-end justify-content-end  m-2 text-center text-white">
                                    <span class="font-bold text-2xl">{{employees[0]?.demitido | divideBy:1000 |
                                        number:'1.3-3'}}</span>
                                    <p>Funcionários Demitidos</p>
                                </div>
                            </div>

                            <div class="card  flex justify-content-center shadow-1 pb-1">
                                <div style="
                                position: relative;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                ">
                                    <img src="assets/img/genero.png" width="70" height="32"
                                        style="position: absolute; z-index: 1;">
                                    <ngx-charts-pie-chart style="z-index: 0;" [view]="[200, 240]"
                                        [scheme]="colorScheme3" [results]="genero" [gradient]="true" [legend]="false"
                                        [legendPosition]="legendPosition" [labels]="false" [doughnut]="true"
                                        (select)="onSelect($event)" (activate)="onActivate($event)"
                                        (deactivate)="onDeactivate($event)">
                                    </ngx-charts-pie-chart>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div class="col-9 md:col-9 lg:col-9 xl:col-9 mt-2" [class.col-12]="!navbarVisible">
                        <div class="grid">
                            <div class="col-12 flex pr-0 pl-0">
                                <div class="col-6 shadow-1">
                                    <ngx-charts-bar-horizontal-stacked [view]="[400 , 200]" [scheme]="colorScheme1"
                                        [results]="atestado" [gradient]="false" [xAxis]="true" [yAxis]="true"
                                        [legend]="false" [showXAxisLabel]="false" [showYAxisLabel]="true"
                                        [showDataLabel]="true" [yAxisLabel]="'ATESTADOS'" (select)="onSelect($event)">
                                    </ngx-charts-bar-horizontal-stacked>
                                </div>
                                <div class="col-6 shadow-1 pieChartGrid">
                                    <div>
                                        <h3 class="text-600">Assinaturas</h3>
                                    </div>
                                    <div>
                                        <ngx-charts-pie-grid [view]="[400, 180]" [scheme]="colorScheme3"
                                            yAxisLabel="Assinaturas" [results]="assinatura" (select)="onSelect($event)">
                                        </ngx-charts-pie-grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="col shadow-1">
                                <ngx-charts-bar-vertical [view]="[400, 360]" [scheme]="colorScheme1" [results]="aviso"
                                    [legend]="false" [xAxis]="true" class="col-6 md:col-12" [yAxis]="true"
                                    [xAxisLabel]="''" [yAxisLabel]="'AVISO DE FERIAS '"
                                    [legendPosition]="legendPosition" [legendTitle]="'AVISO  DE FERIAS '"
                                    [showXAxis]="true" [showYAxis]="true" [gradient]="false" [showLegend]="true"
                                    [showXAxisLabel]="true" [showYAxisLabel]="true" [showDataLabel]="true"
                                    (select)="onSelect($event)">
                                </ngx-charts-bar-vertical>
                            </div>

                            <div class="col shadow-1">
                                <ngx-charts-bar-vertical [view]="[400, 360]" [scheme]="colorScheme1" [results]="recibo"
                                    [legend]="false" [xAxis]="true" [yAxis]="true" [xAxisLabel]="''"
                                    [yAxisLabel]="'RECIBO DE FERIAS'" [legendPosition]="legendPosition"
                                    [legendTitle]="'RECIBO DE FERIAS'" [showXAxis]="true" [showYAxis]="true"
                                    [gradient]="false" [showLegend]="true" [showXAxisLabel]="true"
                                    [showYAxisLabel]="true" [showDataLabel]="true" (select)="onSelect($event)">
                                </ngx-charts-bar-vertical>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


        <!--DASHBOARD COMERCIAL -->
        <div *ngIf="permission?.description === 'comercial'" class="col-10" [class.col-12]="!navbarVisible">

            <div class="col-12">
                <div class="grid">

                    <div class="col-6 sm:col-6 md:col-3" (click)="goPage('/Agreements-list')">
                        <div class="card  flex justify-content-evenly align-items-center shadow-1 pb-1 bg-yellow-400">
                            <h2> {{this.pending.qtd}}</h2>
                            <div class="flex-collumn align-items-end justify-content-end  m-2 text-center">
                                <span class="font-bold text-2xl">{{ this.pending?.finalPrice | currency:'BRL' }}</span>
                                <p>Pendentes</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-6 sm:col-6 md:col-3" (click)="goPage('/faturamento')">
                        <div class="card  flex justify-content-evenly align-items-center shadow-1 pb-1  bg-green-600 text-white">
                            <h2>{{this.approved.qtd}}</h2>
                            <div class="flex-collumn align-items-end justify-content-end  m-2 text-center">
                                <span class="font-bold text-2xl">{{ this.approved?.finalPrice | currency:'BRL' }}</span>
                                <p>Aprovado</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-6 sm:col-6 md:col-3" (click)="goPage('/pedidos-cancelados')">
                        <div class="card  flex justify-content-evenly align-items-center shadow-1 pb-1 bg-red-400 text-white">
                            <h2> {{this.canceled.qtd}}</h2>
                            <div class="flex-collumn align-items-end justify-content-end  m-2 text-center">
                                <span class="font-bold text-2xl">{{ this.canceled?.finalPrice | currency:'BRL' }}</span>
                                <p>Cancelados/Reprovado</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-6 sm:col-6 md:col-3" (click)="goPage('/pedidos-concluidos')">
                        <div class="card  flex justify-content-evenly align-items-center shadow-1 pb-1  bg-blue-500 text-white">
                            <h2> {{this.concluded.qtd}}</h2>
                            <div class="flex-collumn align-items-end justify-content-end  m-2 text-center">
                                <span class="font-bold text-2xl">{{ this.concluded?.finalPrice | currency:'BRL'
                                    }}</span>
                                <p>Concluído</p>
                            </div>
                        </div>
                    </div>


                    <!-- <div class="col-10 md:col-10 lg:col-10 xl:col-10 mt-0" [class.col-12]="!navbarVisible">
                    <div class="grid">
                            <div class="col-6 shadow-1">
                                <ngx-charts-bar-horizontal-stacked
                                [view]="[500 , 280]"
                                [scheme]="colorScheme1"
                                [results]="marginPositive"
                                [gradient]="false"
                                [xAxis]="false"
                                [yAxis]="true"
                                [legend]="false"
                                [showXAxisLabel]="false"
                                [showYAxisLabel]="true"
                                [showDataLabel]="true"
                                [yAxisLabel]="'Margem positiva'"
                                (select)="onSelect($event)">
                                </ngx-charts-bar-horizontal-stacked>
                            </div>

                            <div class="col-6 shadow-1">
                                <ngx-charts-bar-horizontal-stacked
                                [view]="[650 , 280]"
                                [scheme]="colorScheme1"
                                [results]="marginNegative"
                                [gradient]="false"
                                [xAxis]="false"
                                [yAxis]="true"
                                [legend]="false"
                                [showXAxisLabel]="false"
                                [showYAxisLabel]="true"
                                [showDataLabel]="true"
                                [yAxisLabel]="'Margem negativa'"
                                (select)="onSelect($event)">
                                </ngx-charts-bar-horizontal-stacked>
                            </div>
                    </div>
                    
                </div> -->
                </div>
            </div>
        </div>
    </div>
</div>