import {  Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { NavbarService } from '../services/navbar-service';
import { NavbarComponent } from '../components/navbar/navbar.component';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss']
})
export class MenubarComponent implements OnInit {

  menuTop: MenuItem[]  = [];
  public navbarVisible: boolean;
  constructor(
    private navbarService: NavbarService,
    private navBarComponent: NavbarComponent

  ) { }

  ngOnInit(): void {
    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }


  showNavBar(){
    this.navbarService.setNavbarVisible(true);
  }

  hideNavBar(){
    this.navbarService.setNavbarVisible(false);
  }

  logout(){
    this.navBarComponent.logoffUser();
  }


}
