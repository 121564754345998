import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket: Socket;
  IO_URL = environment.URL_IO

  constructor() {
    // Conectar ao servidor Socket.IO
    this.socket = io(this.IO_URL,{
      protocols: ['websocket'],
    });
  }

  // Enviar mensagem para o servidor
  sendMessage(msg: string) {
    this.socket.emit('message', msg);
  }

  // Receber mensagens do servidor
  getMessage(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('message', (msg: string) => {
        observer.next(msg);
      });
    });
  }

  // Fechar a conexão
  disconnect() {
    this.socket.disconnect();
  }
}
