<div class="surface-0">
  <app-menubar></app-menubar>
  <div class="grid">
    <div class="col-2 bg-secondary" *ngIf="navbarVisible">
      <app-navbar></app-navbar>
    </div>
    <div class="col-10" [class.col-12]="!navbarVisible">
      <div class="card">
        <p-table
          #dt2
          [value]="results"
          dataKey="id"
          [rows]="10"
          [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10, 50, 100]"
          [paginator]="true"
          [lazy]="true"
          [totalRecords]="totalRecords"
          currentPageReportTemplate="Mostrando de {first} a {last} de um total de {totalRecords} registro(s)"
          [tableStyle]="{ 'min-width': '100%' }"
          (onLazyLoad)="searchEmployee($event)"
          [loading]="loadingTable"
          scrollDirection="horizontal"
          [scrollable]="true"
          scrollHeight="600px"
          styleClass="p-datatable-striped"
        >
          <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-start">
              <ul class="flex">
                <li class="mr-5">Ativo<img src="assets/img/status/ativo.png" width="24px" height="24px"/></li>
                <li class="mr-5">Afastado<img src="assets/img/status/afastado.png" width="24px" height="24px"/></li>
                <li class="mr-5">Demitido<img src="assets/img/status/demitido.png" width="24px" height="24px"/></li>
                <li class="mr-5">Férias<img src="assets/img/status/ferias.png" width="24px" height="24px"/></li>
              </ul>
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th></th>
              <th>#</th>
              <th pSortableColumn="nome" class="thnome">
                Nome<p-sortIcon field="nome"></p-sortIcon
                  ><p-columnFilter
                  [showAddButton]="false"
                  [showOperator]="false"
                  type="text"
                  field="nome"
                  display="menu"
                  ></p-columnFilter>
                </th>
                <th pSortableColumn="nome_fantasia" class="thempresa">
                  Empresa <p-sortIcon field="nome_fantasia"></p-sortIcon>
                  <p-columnFilter
                    [showAddButton]="false"
                    [showOperator]="false"
                    type="text"
                    field="nome_fantasia"
                    display="menu"
                  ></p-columnFilter>
                </th>

                <th pSortableColumn="matricula" class="thempresa">
                  Matricula
                  <p-columnFilter
                    [showAddButton]="false"
                    [showOperator]="false"
                    type="text"
                    field="matricula"
                    display="menu"
                  ></p-columnFilter>
                </th>

              <th pSortableColumn="cpf">
                CPF <p-sortIcon field="cpf"></p-sortIcon>
                <p-columnFilter
                  [showAddButton]="false"
                  [showOperator]="false"
                  type="text"
                  field="cpf"
                  display="menu"
                ></p-columnFilter>
              </th>             
            
              <th>Margem</th>
              <th>Way</th>
              <th>Saldo</th>
              <th style="width: 5rem">Ações</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-employee let-rowIndex="rowIndex" let-expanded="expanded">
            <tr>
              <td>
                <button type="button" pButton pRipple [pRowToggler]="employee" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
              </td>
              <td class="flex justify-content-center">
                <ng-template [ngIf]="employee?.usuarios?.notifications_tokens?.length > 0" [ngIfElse]="notification">
                  <div class="userYesNotification"></div>
                </ng-template>
                
                <ng-template #notification>
                  <div class="userNotNotification"></div>
                </ng-template>
                <img [src]="checkStatusEmploee(employee.sitfolha)" width="24px" height="24px"/>
              </td>
              <td style="min-width: 20rem">
                {{ employee.nome }}
              </td>
              <td>
                {{ employee.empresas.nome_fantasia }}
              </td>
              <td> {{ employee.matricula }}</td>
              <td>
                {{ employee.cpf }}
              </td>

              <td>
                 <!--conveios--ativos-->
                 <p-button label="" styleClass="p-button-text"
                 (click)="getListProductConvenios(employee)"
                 >  {{ employee.concession_margin[0]?.margin_liquida | currency:'BRL' }}
               </p-button>
              </td>
              <td>
                <!--wayconsig-->
                <button  
                  pButton 
                  pRipple 
                  type="button" 
                  icon="pi pi-money-bill"
                  class="p-button-rounded p-button-info p-button-text p-button-raised"
                  (click)="searchMarginMota(employee)">
                </button>
              </td>
              <!---saldo---margem--->
              <td class="saldo">
               {{ employee.saldo_magin | currency:'BRL' }}
              </td>
              <td style="min-width: 5rem" class="flex align-items-center justify-content-center gap-2">
                <div class="flex align-items-center justify-content-center gap-2">
                  <button
                    pButton
                    type="button"
                    pRipple
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-info p-button-text p-button-raised"
                    (click)="editEmployee(employee, rowIndex)"
                    [loading]="loadingModalEmployee === rowIndex"
                  ></button>
                </div>
                <!--contra-cheque-->
                <div class="flex align-items-center justify-content-center gap-2">
                  <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-file-pdf"
                    class="p-button-rounded p-button-info p-button-text p-button-raised"
                    (click)="showDialog(employee)"
                  ></button>
                </div>
                <div 
                class="flex align-items-center justify-content-center gap-2" 
                *ngIf="permission.roles.description === 'rhapp'">
                  <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-eye"
                    class="p-button-rounded p-button-info p-button-text p-button-raised"
                    (click)="showmodalSearchPassword(employee)"
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="rowexpansion" let-employee>
            <tr>
              <td colspan="9">
                  <div class="p-1">
                      <p-table [value]="employee.items" dataKey="id">
                          <ng-template pTemplate="header">
                              <tr>
                                  <th>Dados</th>
                                  <th> Orgão </th>
                                  <th>Data de admissão </th>
                                  <th> Email </th>
                                  <th> Celular </th>
                                  <th> Telefone </th>
                                  <th>Adesões</th>
                              </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-i>
                              <tr>    
                                <td><img [src]="hasPendencyData(employee)" width="30px" height="30px"/></td>
                                <td>{{employee.centro_custo}}</td>
                                <td>{{i.dt_admissao | dataProtheus}}</td>
                                <td>{{i.email}}</td>
                                <td>{{i.celular}}</td>
                                <td>{{i.telefone}}</td>
                                <td>
                                <!--adesões-->
                                <p-button 
                                label="" 
                                styleClass="p-button-text"
                                (click)="showModalAdesoes(employee.parcelas)"> 
                                {{employee.totalAdesao || '0' |currency:'BRL'}}
                                </p-button>
                                </td>

                              </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage">
                              <tr>
                                  <td colspan="6">There are no order for this product yet.</td>
                              </tr>
                          </ng-template>
                      </p-table>
                  </div>
              </td>
          </tr>
          </ng-template>

          <ng-template pTemplate=" emptymessage">
            <tr>
              <td colspan="5">Nenhum funcionario encontrado.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <div class="col-12">
      <p-dialog
        header="Visualizar contracheque"
        [(visible)]="panelGetCheque"
        [modal]="true"
        [style]="{ width: '30vw', height: '55%' }"
        [draggable]="false"
        [resizable]="false"
      >
        <p-toast key="toastContraCheque"></p-toast>
        <div *ngIf="!carregarContraCheque">
          <div class="field grid">
            <label for="cpf" class="mr-2" style="width:150px">CPF:</label>
            <input type="text" pInputText [(ngModel)]="cpf" disabled />
          </div>
          <div class="field grid">
            <label for="tipo" class="mr-2" style="width:150px">Tipo de contracheque:</label>
          
            <p-dropdown
              [options]="tpsContraCheque"
              [(ngModel)]="tipo"
              optionLabel="nome"
              optionValue="valor"
            ></p-dropdown>
          </div>
          <div class="field grid">
            <label class="mr-2" style="width:150px">Periodo:</label>
            <p-calendar
              [(ngModel)]="date"
              view="month"
              dateFormat="mm/yy"
              [readonlyInput]="true"
            ></p-calendar>
          </div>
          <!--AQUI e o Botão contracheck-->
          <div class="field grid" style="margin-top: 150px; justify-content: center;">
            <p-button
              label="Gerar contra cheque"
              icon="pi pi-file-pdf"
              (click)="generatePDF()"
            ></p-button>
          </div>
        </div>
        <p-progressSpinner
          *ngIf="carregarContraCheque"
          [style]="{
            width: '20%',
            height: '20%',
            display: 'flex',
            'margin-top': '30%',
            'justify-content': 'center'
          }"
          class="center"
        >
        </p-progressSpinner>
        <div class="overlay" *ngIf="isLoadingContracheque">
          <p-progressSpinner
            [style]="{ width: '50px', height: '50px' }"
          ></p-progressSpinner>
        </div>
      </p-dialog>
    </div>

    <div class="col-12">
      <div class="card flex justify-content-center gap-2">
        <p-toast></p-toast>
        <p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>
      </div>
      <p-dialog
        [(visible)]="requestDialogExport"
        [style]="{ width: '450px' }"
        header="Exportação"
        [modal]="true"
        styleClass="p-fluid"
      >
        <!-- <ng-template pTemplate="content"> -->
        Selecione os campos
        <p-multiSelect
          appendTo="body"
          [options]="campos"
          [(ngModel)]="selectedCampos"
          optionLabel="name"
        ></p-multiSelect>

        <div *ngIf="typeFileExport === 'PDF'">
          Selecione o modo de impressão
          <p-selectButton
            [options]="printingModes"
            [(ngModel)]="selectedPrintingMode"
            optionLabel="label"
            optionValue="value"
          ></p-selectButton>
        </div>

      </p-dialog>
    </div>

    <!-- Modal de Edição do usuário -->
    <div class="col-12">
      <p-dialog
        header="Atualizar cadastro da empresa"
        [(visible)]="showModalEditEmployee"
        [style]="{ width: '70vw' }"
        *ngIf="showModalEditEmployee"
      >
        <form [formGroup]="editingEmployeeForm" novalidate>
          <div class="grid">
            <div class="p-inputgroup field flex">
              <div class="col-6">
                <span class="p-inputgroup-addon"> Perfis </span>
              </div>
              <div class="col-6">
                <p-multiSelect
                  [options]="optionsRoles"
                  formControlName="roles"
                  optionLabel="name"
                  optionValue="code"
                  display="chip"
                  class="flex w-full"
                ></p-multiSelect>
              </div>
            </div>

            <div class="p-inputgroup field flex">
              <div class="col-6">
                <span class="p-inputgroup-addon"> Nome </span>
              </div>
              <div class="col-6">
                <input
                  type="text"
                  class="flex w-full"
                  pInputText
                  placeholder="Nome"
                  formControlName="nome"
                />
              </div>
            </div>

            <div class="p-inputgroup field flex">
              <div class="col-6">
                <span class="p-inputgroup-addon"> Email </span>
              </div>
              <div class="col-6">
                <input
                  type="text"
                  class="flex w-full"
                  pInputText
                  placeholder="Email"
                  formControlName="email"
                />
              </div>
            </div>

            <div class="p-inputgroup field flex">
              <div class="col-6">
                <span class="p-inputgroup-addon"> Telefone </span>
              </div>
              <div class="col-6">
                <input
                  type="text"
                  class="flex w-full"
                  pInputText
                  placeholder="Telefone"
                  formControlName="telefone"
                />
                
              </div>
            </div>

            <div class="p-inputgroup field flex">
              <div class="col-6">
                <span class="p-inputgroup-addon"> Celular </span>
              </div>
              <div class="col-6">
                <input
                  type="text"
                  class="flex w-full"
                  pInputText
                  placeholder="Celular"
                  formControlName="celular"
                />
              </div>
            </div>
            <div class="p-inputgroup field flex">
              <div class="col-6">
                <span class="p-inputgroup-addon"> Status RHAPP </span>
              </div>
              <div class="col-6">
                <input
                  type="text"
                  class="flex w-full"
                  pInputText
                  placeholder="Status RHAPP"
                  formControlName="status_rhapp"
                />
              </div>
            </div>
          </div>
        </form>
        <ng-template pTemplate="footer" class="flex">
          <p-button
            icon="pi pi-save"
            label="Atualizar"
            class="mt-4"
            [loading]="loadingUpdateEmployee"
            (click)="saveEmployeeDb()"
          ></p-button>
        </ng-template>
      </p-dialog>
    </div>
    <!--modal de adesoes-->
    <p-dialog header="Adesões" [(visible)]="showModalAdesao" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
        <p-table [value]="listAdesoes" [tableStyle]="{ 'min-width': '50rem' }">
          <ng-template pTemplate="header">
              <tr>
                  <th>Produto</th>
                  <th>Valor</th>
                  <th>Data Pedido</th>
                  <th>1ºMes(desc)</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-adesao>
              <tr>
                  <td>{{ adesao.produto }}</td>
                  <td>{{ adesao.valor |currency:'BRL'}}</td>
                  <td>{{ adesao.dataPedido | datetime}}</td>
                  <td>{{ adesao.dataPrimeiroDesconto | datetime}}</td>
              </tr>
          </ng-template>
        </p-table>
  </p-dialog>

   <!--list--products--convenios-->
   <p-dialog header="Convenios" [(visible)]="showModalConvenioAtivo" [modal]="true" [style]="{ width: '80vw' }" [draggable]="true" [resizable]="true">
    <p-table [value]="listConvenios" [autoLayout]="false" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
        <tr>
          <th>Label</th>
          <th>Valor</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-convenio>
        <tr>
          <td style="width: 180px; margin-right: 30px;">NOME:</td>
          <td><span>{{convenio.nome_funcionario}} - CPF: {{convenio.cpf | cpf}}  </span></td>
        </tr>
        <tr>
          <td style="width: 180px;  margin-right: 30px;">MARGEM BRUTA:</td>
          <td>
            <span> {{convenio.resultCalMargemBruta | currency:'BRL'}}</span>
           <a href="javascript:void(0);" (click)="showModalVerbasProventos()">
             (30% PROVENTOS COSIGNAVÉIS)
           </a>

           <span> - PERIODO: {{ convenio.periodo | date:'MM/YYYY'}} </span>
          </td>
        </tr>
        <tr>
          <td style="width: 180px;  margin-right: 30px;">DESCONTOS:</td>
          <td>
            <span>{{convenio.calcDesconto | currency:'BRL'}}</span>
            <a href="javascript:void(0);" (click)="showModalVerbasDescontos()">(DESCONTOS COSIGNAVÉIS - CONTRACHEQUE)</a>
          </td>
        </tr>
        <tr>
          <td style="width: 180px;  margin-right: 30px;">MARGEM LIQUIDA:</td>
          <td>
            <span> {{convenio.margemLiquida | currency:'BRL'}}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
    
    <div class="grid">
      <div class="col-12">
        <h3>Acompanhamento RHapp</h3>
      </div>
    </div>
    
    <p-table [value]="listConvenios" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
          <tr>
              <th>Verba</th>
              <th>Produto</th>
              <th>Valor</th>
              <th>Data Pedido</th>
              <th>1ºMes(desc)</th>
              <th>Parcela atual</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-convenio>
          <tr *ngFor="let item of convenio.list">
              <td>{{ item.codigoProduto }}</td>
              <td>{{ item.nome }}</td>
              <td>{{ item.valorParcela |currency:'BRL'}}</td>
              <td>{{ item.dataPedido | datetime}}</td>
              <td>{{ item.dataPrimeiroDesconto | datetime}}</td>
              <td> {{item.parcelaAtual}} </td>
          </tr>
      </ng-template>
    </p-table>
</p-dialog>

<!--modal--verbas--proventos-->
<p-dialog header="Proventos" [(visible)]="typeProventos" [style]="{width: '50vw'}">
  <p-table [value]="listConvenios" [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="header">
        <tr>
            <th>Verba</th>
            <th>Descrição</th>
            <th>Valor</th>
            
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-convenio>
        <tr *ngFor="let item of convenio.verbasProventos">
            <td>{{ item.cod_verba }}</td>
            <td>{{ item.desc_verba }}</td>
            <td>{{ item.valor |currency:'BRL'}}</td>
           
        </tr>
    </ng-template>
  </p-table>
</p-dialog>

<!--modal--verbas--descontos-->
<p-dialog header="Proventos" [(visible)]="typeDesconto" [style]="{width: '50vw'}">
  <p-table [value]="listConvenios" [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="header">
        <tr>
            <th>Verba</th>
            <th>Descrição</th>
            <th>Valor</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-convenio>
        <tr *ngFor="let item of convenio.verbasDescontos">
            <td>{{ item.cod_verba }}</td>
            <td>{{ item.desc_verba }}</td>
            <td>{{ item.valor |currency:'BRL'}}</td>
        </tr>
    </ng-template>
  </p-table>
</p-dialog>

<!----->
<p-dialog header="SENHA DE USUÁRIO" [(visible)]="showmodalSearch" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
  <ul *ngFor="let item of seachPassword">
    <li><span>NOME:</span> {{item.nome}} </li>
    <li><span>CPF:</span>  {{item.cpf |cpf}} </li>
    <li><span>PASSWORD:</span> {{item.password === null ? 'USUÀRIO NÂO CADASTRADO' : item.password}} </li>
    <li><span>RESET CODE:</span> {{item.idcode === null ? 0 : item.reset_code}} </li>
  </ul>
</p-dialog>

 <!--Margin--mota-->
 <p-dialog header="Margin Mota" [(visible)]="showModalMota" [style]="{width: '60vw'}" *ngIf="showModalMota">
  <p-table [value]="dataMarginMota" [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="header">
        <tr>
            <th>Funcionário</th>
            <th>Cpf</th>
            <th>Matricula</th>
            <th>Margin</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-mota>
        <tr>
            <td>{{ mota.nome }}</td>
            <td>{{ mota.cpf }}</td>
            <td>{{ mota.matricula }}</td>
            <td>{{ mota.valorMargemDisponivel | currency: 'BRL'}}</td>
        </tr>
    </ng-template>
</p-table>
</p-dialog>
  </div>
</div> 
