import { APP_ID, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { PayloadNotification } from 'src/app/models/payloadNotification';
import { VacationService } from 'src/app/services/gpe/vacation.service';
import { NavbarService } from 'src/app/services/navbar-service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-list-ferias-recibo',
  templateUrl: './list-ferias-recibo.component.html',
  styleUrls: ['./list-ferias-recibo.component.scss']
})
export class ListFeriasReciboComponent implements OnInit {

  vocationReceipt:any[] = [];
  selectedVocationReceipt: any[] =[];
  loading: boolean= false;
  public docForm: FormGroup;
  visible: boolean= false;
  navbarVisible: boolean;
  docCancel = [];
  checkboxValue: boolean= false;
  totalRecords:number =0;
  printModal:boolean =  false;
  loadingButtonAviso = false;
  loadingButtonRecibo = false;
  avisoFilter:any[] = [];

  constructor( 
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private vacationService: VacationService,
    private utilsService: UtilsService,
    public formBuilder: FormBuilder,
    private navbarService: NavbarService
    ) { }

  ngOnInit(): void {
    this.docForm = this.formBuilder.group({
      motivo_cancelamento: ['', [Validators.required]],
    });


    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });

    this.avisoFilter = [
      {nome_fantasia: 'nome_fantasia'},
      {nome: 'nome'},
      {cpf:'cpf'},
      {fer_ini: 'fer_ini'},
      {fer_fim: 'fer_fim'},
      {mes_movimento: 'mes_movimento'},
      {warning_sended_at: 'warning_sended_at'}
    ];

  }

  async searchVacationsReceipt(event: LazyLoadEvent) {
    this.vocationReceipt = [];
    this.loading = true;
    this.totalRecords  = 0;
    this.vacationService.getVacationsReceipt(event, this.totalRecords).subscribe({
      next: async (res: any) => {
      const promiseAll =  res.fer.map(async(vocation) => {    
          this.vocationReceipt.push({
            ...vocation,
            contador: vocation.count_day_vocation === "" ? await this.utilsService.countDayReceiptVocation(vocation.fer_ini) : vocation.count_day_vocation,
          })
        })
        
      await Promise.all(promiseAll)
      this.totalRecords = res.totalRecords
      
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
        console.log(this.vocationReceipt);
        this.loading = false;
      },
    });
  }
  

  uploadVacationsReceipt(type: string) {
    if(this.selectedVocationReceipt.length === 0){
      
    return  this.messageService.add({
        severity: 'warn',
        summary: 'Recibo de férias',
        detail:'Seleciona no maximo 50 item',
      });
    }else{
      this.loading = true;
      this.confirmationService.confirm({
        message: 'Você tem certeza que deseja enviar?',
        header: 'Atenção',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          try {
        let tokens = [];
        const filterTokens =  this.selectedVocationReceipt.filter((item) => item.usuarios.notifications_tokens.length > 0 );
          filterTokens.map((item) => {
          tokens.push(item.usuarios.notifications_tokens[0]?.token)
        });
          
            const sendToken:PayloadNotification =  {
              tokens: tokens,
              notification: {
                title: 'Olá',
                body: 'Seu recibo de férias está disponível no aplicativo.'
              },
              data: {
                action: '/ferias',
                intent: 'FERIAS_INTENT'
              }
          }
            this.vacationService.sendVacationWarningReceipt(this.selectedVocationReceipt, sendToken, type).subscribe({
              next: (res: any) => {
                if (res.vacationSent) {
                  this.messageService.add({
                    severity: 'info',
                    summary: 'Sucesso',
                    detail:
                      'A(s) férias foram enviadas para o(s) funcionário(s) selecionados.',
                  });
                } else {
                  this.messageService.add({
                    severity: 'warn',
                    summary: 'Ops, operação abortada.',
                    detail:
                      'A(s) férias já foram enviadas para o(s) funcionário(s) selecionados.',
                  });
                }
              },
              error: () => {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Erro',
                  detail:
                    'Ocorreu um erro ao tentar enviar as férias para o(s) funcionário(s) selecionados.',
                });
              },
              complete: () => {
                this.loading = false;
                this.selectedVocationReceipt = [];
                this.searchVacationsReceipt({ first: 0, rows: 10 });
              },
            });
          } catch (error) {
            console.error(error);
          }
        },
        reject: () => {
          this.loading = false
        },
        acceptLabel: 'SIM',
        rejectLabel: 'NÃO',
      });
    }
  }

  /* open pdf   recibo*/
  async printVacationReceipt() {
    
    if(this.selectedVocationReceipt[0].ferias_itens.length > 0){
      this.loading = true;
      this.loadingButtonRecibo = true;
      this.vacationService.getLayoutPdfReciboFerias(this.selectedVocationReceipt).subscribe({
        next: (res:any) => {
          const blob = new Blob([new Uint8Array(res.pdfBUffer.data)], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = res.document; 
            a.click();
        },
        complete: () => {
          this.loading = false;
          this.selectedVocationReceipt = [];
          this.printModal = false
          this.loadingButtonRecibo = false;
        },
        error: () => {}
      })
    }else{
      this.messageService.add({
        severity: 'info',
        summary: 'Férias',
        detail: 'Aguardar pagamento de férias',
      });
    }
  }


  async printVacationWarning() {
      this.loading = true;
      this.loadingButtonAviso = true;
      this.vacationService.getLayoutPdfAvisoFerias(this.selectedVocationReceipt).subscribe({
        next: (res:any) => {
          const blob = new Blob([new Uint8Array(res.pdfBUffer.data)], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = res.document; 
          a.click();
        },
        complete: () => { 
          this.loading = false;
          this.selectedVocationReceipt = [];
          this.printModal = false
          this.loadingButtonAviso = false;
        },
        error: () => { }
      });
    
  }

  openPrintModal(receipt:any){
    this.selectedVocationReceipt = [receipt];
    this.printModal = true
  }

  acceptedNotificationsReceipt(vacation: any) {
    return vacation.usuarios.notifications_tokens.length > 0 ? 'Sim' : 'Não';
  }

  verifyVacationsSelected() {
    if (this.selectedVocationReceipt.length === 0) {
      this.printAlert();
      return false;
    } else {
      return true;
    }
  }

  printAlert() {
    this.messageService.add({
      severity: 'warn',
      summary: 'Ops',
      detail:
        'Primeiro selecione pelo menos um item de férias para prosseguir.',
    });
  }

  showmodal(receipt:any){
  this.visible = true;
  this.selectedVocationReceipt = [receipt]
   receipt.empresas.doc_signature.map((item:any) => {
    this.docCancel.push({
      empresa_fk: receipt.empresa_fk,
      usuario_fk: receipt.usuario_fk,
      fer_id: receipt.id,
      wesign: {
       id_doc:  item.id_doc
      }
    })
   })
  
   
  }

  cancelDocWessign(){
    if(this.selectedVocationReceipt.length > 0){
      this.loading = true
      const motivo = '-'
      const motivo_cancelamento = this.docForm.value.motivo_cancelamento;
      let tokens = [];
      const filterTokens =  this.selectedVocationReceipt.filter((item) => item.usuarios.notifications_tokens.length > 0 );
         filterTokens.map((item) => {
         tokens.push(item.usuarios.notifications_tokens[0]?.token)
       });

       const sendToken:PayloadNotification =  {
        tokens:[], //tokens,
        notification: {
          title: 'Olá',
          body: 'Férias canceladas, verificar com departamento pessoal'
        },
        data: {
          action: '/ferias',
          intent: 'FERIAS_INTENT'
        }
    }
  
      this.vacationService.definitiveCancellationDocVacantion(this.docCancel, motivo_cancelamento, motivo, sendToken).subscribe({
        next: (res:any) => {
          if(res.status === 201 && res.cancelDoc === true && res.notificationSent === true){
            this.messageService.add({
              severity: 'info',
              summary: 'Férias cancelada',
              detail: res.message,
            });
          }
          if(res.status === 201 && res.cancelDoc === true && res.notificationSent === false){
            this.messageService.add({
              severity: 'warn',
              summary: 'Férias cancelada',
              detail: res.message,
            });
          }
        },
        complete: () => {
          this.docCancel = [];
          this.docForm.reset();
          this.visible = false;
          this.loading = false;
          tokens = [];
          this.searchVacationsReceipt({ first: 0, rows: 10 });
        },
        error: (err) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Férias cancelada',
            detail: 'Não foi possivél fazer cancelamento de ferias',
          });
        }
       })

    }else{
      this.messageService.add({
        severity: 'warn',
        summary: 'Férias',
        detail: 'Selecionce ao menos um item',
      });
    }
    
    }

  async cancelDocwesignDataInavalid(){
      this.loading = true
      const motivo = 'invalido';
      const motivo_cancelamento = this.docForm.value.motivo;
      const message= 'Férias canceladas, verificar com departamento pessoal';
      const dataDoc = await this.utilsService.dataSendWarinigVacation(this.selectedVocationReceipt);

      this.vacationService.cancelDocdataInavalid(dataDoc, motivo_cancelamento, motivo, message).subscribe({
        next: (res:any) => {
          if(res.status === 201 && res.cancelDoc === true && res.notificationSent === true){
            this.messageService.add({
              severity: 'info',
              summary: 'Férias cancelada',
              detail: res.message,
            });
          }
          if(res.status === 201 && res.cancelDoc === true && res.notificationSent === false){
            this.messageService.add({
              severity: 'warn',
              summary: 'Férias cancelada',
              detail: res.message,
            });
          }
        },
        complete: () => {
          this.docCancel = [];
          this.docForm.reset();
          this.visible = false;
          this.loading = false;
          this.searchVacationsReceipt({ first: 0, rows: 10 });

        },
        error: (err) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Férias cancelada',
            detail: 'Não foi possivél fazer cancelamento de ferias',
          });
        }
       })
    }

    filterSelectedreceiptTrue(){
     const filterReceipt =  this.selectedVocationReceipt.filter((item) => item.sended_receipt !== true);
     this.selectedVocationReceipt = filterReceipt;
    }

    sendNotification(sendnotification:any){
        this.loading = true;
        const notification:PayloadNotification =  {
              tokens: [sendnotification[0].token],
              notification: {
                  "title": "Olá",
                  "body": "Seu aviso de férias está disponível no aplicativo."
              },
              data: {
                  action: "/ferias",
                  intent: "FERIAS_INTENT"
              }
      }
        this.vacationService.sendNotificationVacation(notification).subscribe({
          next: (res:any) => {
            console.log(res);
            
            this.loading = false;
            this.messageService.add({
              severity: 'info',
              summary: 'Notificação',
              detail: res.message,
            });
          },
          complete() {
            this.loading = false;
          },
          error(err) {
            this.loading = false;
            this.messageService.add({
              severity: 'warn',
              summary: 'Notificações',
              detail: 'Não foi possivél enviar notificação',
            });
          },
        })
    }

    async exportExcel(){
      if(this.selectedVocationReceipt.length > 0){
        await this.utilsService.exportExcell(this.avisoFilter,  this.selectedVocationReceipt);
      }else{
        this.messageService.add({
          severity: 'warn',
          summary: 'Export Excel',
          detail: 'Selecione um ou mais items',
        });
      } 
    }

}
